<template>
  <div>
    <a-row :gutter="[8, 8]">
      <a-col :span="8">
        <a-card title="可视化看板" size="small">
          <div style="text-align: center; font-size: 36px; font-weight: 600; color: #1890ff; margin: 12px 0">
            <a-button
              @click="navigateTo('/kanban/production')"
              class="button-style"
              style="display: block; background-color: #1890ff; color: white; margin: 23px auto"
              >生产看板</a-button
            >
          </div>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card title="今日入库完成单量" size="small">
          <div style="text-align: center; font-size: 36px; font-weight: 600; color: #1890ff; margin: 12px 0">
            {{ item.completed_stock_in_order_count }}
          </div>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card title="今日出库完成单量" size="small">
          <div style="text-align: center; font-size: 36px; font-weight: 600; color: #1890ff; margin: 12px 0">
            {{ item.completed_stock_out_order_count }}
          </div>
        </a-card>
      </a-col>

      <a-col :span="24">
        <a-card title="待办事项" size="small">
          <a-card-grid style="width: 25%; text-align: center; cursor: pointer" @click="navigateTo('/warehouse/inStock')">
            <a-statistic title="待入库" :value="item.stock_in_task_count" />
          </a-card-grid>
          <a-card-grid style="width: 25%; text-align: center; cursor: pointer" @click="navigateTo('/warehouse/outStock')">
            <a-statistic title="待出库" :value="item.stock_out_task_count" />
          </a-card-grid>
          <a-card-grid
            style="width: 25%; text-align: center; cursor: pointer"
            @click="$router.push({ path: '/report/stock_report', query: { inventory_status: 'abnormal' } })"
          >
            <a-statistic title="库存预警" :value="item.inventory_warning_count" />
          </a-card-grid>
          <a-card-grid style="width: 25%; text-align: center; cursor: pointer" @click="navigateTo('/approval/approval_task')">
            <a-statistic title="待审批任务" :value="item.approval_task_count" />
          </a-card-grid>
        </a-card>
      </a-col>
      <a-col :span="12">
        <ProductionTrend />
      </a-col>

      <a-col :span="12">
        <InventoryTopProduct />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { homeOverview } from "@/api/statistic";

export default {
  components: {
    ProductionTrend: () => import("./ProductionTrend"),
    InventoryTopProduct: () => import("./InventoryTopProduct"),
  },
  data() {
    return {
      loading: false,
      item: {},
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      homeOverview()
        .then((data) => {
          this.item = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    navigateTo(pathName) {
      this.$router.push({ path: pathName });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
